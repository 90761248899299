import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Phone from "../../device/phone";
import { OutboundLink } from "gatsby-plugin-google-gtag";

import appPoster from "../../../images/screenshot/cartogram/0.webp";
import appScreenshot1 from "../../../images/screenshot/cartogram/1.webp";
import appScreenshot2 from "../../../images/screenshot/cartogram/3.webp";
import appVideoMp4 from "../../../assets/cartogram/cartogram.mp4";
import appVideoWebm from "../../../assets/cartogram/cartogram.webm";

export default function CartogramHero(props) {
  return (
    <div>
      <section className="flex-col w-screen lg:p-20 bg-primaryCartogram">
        <section className="lg:flex pt-20">
          <div className="flex-auto m-auto">
            <StaticImage
              src="../../../images/icon/cartogram.svg"
              alt="Cartogram - Live Map Wallpaper Icon"
              className="h-20 lg:h-40 rounded mx-auto my-2"
              placeholder="none"
              layout="fixed"
              width={100}
              height={100}
            />
            <h3 className="font-poppins text-white text-center text-3xl lg:text-4xl p-2">
              Cartogram
            </h3>
            <h4 className="font-main text-white/50 text-center">
              Live Map Wallpaper
            </h4>
            <div className="w-40 mx-auto mt-4">
              <OutboundLink href="https://play.google.com/store/apps/details?id=com.round_tower.app.android.wallpaper.cartogram&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img
                  alt="Get it on Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                />
              </OutboundLink>
            </div>
          </div>

          <div className="flex lg:flex-row lg:w-2/3 p-4 mx-auto">
            <div className="h-10 transform -rotate-12 skew-y-3">
              <Phone
                id={"cartogram-image-0"}
                poster={appScreenshot1}
                mp4={""}
                webm={""}
              />
            </div>

            <div className="mt-10 transform -skew-y-6 rotate-12 z-10">
              <Phone
                id={"cartogram-video"}
                poster={appPoster}
                mp4={appVideoMp4}
                webm={appVideoWebm}
              />
            </div>

 
          </div>
        </section>

        <section className="grid grid-cols-2 gap-2 mx-auto py-10 max-w-lg">
          <div className="flow-col p-4">
            <p className="font-main font-semibold text-4xl p-2 text-white text-center">
              250<span className="opacity-20">,</span>000
              <span className="opacity-50">+</span>
            </p>
            <p className="font-title text-sd text-white/50 text-center">
              Downloads
            </p>
          </div>

          <div className="flow-col p-4">
            <p className="font-main font-semibold text-4xl p-2 text-white text-center">
              4<span className="opacity-20"> . </span>8
              <span className="opacity-50"> </span>
            </p>
            <p className="font-title text-sd text-white/50 text-center">
              Stars
            </p>
          </div>

          <div className="flow-col p-4">
            <p className="font-main font-semibold text-4xl p-2 text-white text-center">
              5
            </p>
            <p className="font-title text-sd text-white text-center">
              <span className="opacity-50">Languages</span> <br /> 🇺🇸 🇪🇸 🇩🇪 🇫🇷
              🇮🇹{" "}
            </p>
          </div>

          <div className="flow-col p-4">
            <p className="font-main font-semibold text-6xl p-2 text-white text-center">
              &#8734;
            </p>
            <p className="font-title text-sd text-white text-center opacity-50">
              Possibilites
            </p>
          </div>
        </section>
      </section>
    </div>
  );
}
