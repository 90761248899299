import React from "react";
import Social from "./../social/social"
import logo from "./../../images/logo_no_padding.webp"

const Footer = () => (
  <footer className="mx-auto bg-background3 py-16" id="footer">
     <section>
     <img className="container object-center my-6 mx-auto w-24 opacity-70 object-scale-down" src={logo} alt={"Round Tower Logo"}/>
      <p className="py-4 text-xs font-main text-neutral-500 text-center">Round Tower Software Studios Ltd<br/>Ardmore, Co. Waterford<br/>Ireland<br/>🇮🇪</p>
      <Social />
    </section>
    <section className="container mx-auto p-4">
      <p className="py-4 text-xs font-main text-neutral-500 text-center"><a href="/privacy/round-tower.html">Privacy Policy</a></p>
      <p className="py-1 text-xxs font-main text-neutral-500 text-center">Comapny Number: 631926</p>
      <p className="py-1 text-xxs font-main text-neutral-500 text-center">VAT Number: IE3560494KH</p>
      <p className="py-8 text-xs font-main text-neutral-400 text-center">Copyright {new Date().getFullYear()}</p>
    </section>
   
  </footer>
);

export default Footer;
