import React, { Fragment } from "react"

import Footer from "../components/footer/footer"
import Seo from "../components/seo/seo"
import Cartogram from "../components/apps/cartogram/cartogram"

export default class CartogramPage extends React.Component {
  render() {
    return(
      <Fragment>
        <Cartogram />
        <Footer />
      </Fragment>
    )
  }
}

export const Head = () => {
  return <Seo title={"Cartogram"} />;
};