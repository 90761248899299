import React from "react";
import Fade from "react-reveal/Fade";

import CartogramHero from "./hero";

export default function Catrogram() {
  return (
    <section>
      <CartogramHero />
      <Fade bottom></Fade>

        <Fade bottom>
            <p className="font-main">
              Cartogram for Android allows you to create beautiful styled Map
              Wallpapers for any Android device.
            </p>
        </Fade>

        <Fade bottom>
          <section>
            Features
            <ul>
              <li className="">30+ Map Styles</li>
              <li >Live Wallpaper</li>
              <li>Instant App</li>
              <li>Material Design</li>
            </ul>
          </section>
        </Fade>

      <p className="">
        <a
          href="/privacy/privacy-policy-cartogram.html"
          rel="noopener noreferrer"
          target="_blank">
          Privacy Policy
        </a>
      </p>
    </section>
  );
}
